<template>
  <main>
    <trac-loading v-if="isLoading"></trac-loading>
    <div v-else>
      <!-- <h1 class="text-2xl mt-5 font-bold text-blue-800">
        Support Form
      </h1> -->
      <div class="flex flex-wrap xl:-mx-4 pb-5">
        <!-- <div class="w-full xl:mb-4 xl:px-4">
          <p
            :class="errorMssgColor ? 'text-red-800' : 'text-green-600'"
            v-if="errorMssg"
          >
            {{ errorMssg }}
          </p>
        </div> -->

        <div class="w-full xl:my-4 xl:px-4 mt-5">
          <div
            class="md:w-3/4 p-5 border-2 border-gray-100 bg-white rounded-lg"
          >
            <form
              class="w-full max-w-full"
              @submit.prevent="newSupportFormRequest"
            >
              <div class="flex flex-wrap -mx-3 mb-3">
                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                  <label
                    class="block  tracking-wide text-gray-700 text-xs font-bold mb-2"
                    for="grid-first-name"
                  >
                    First Name <span class="text-red-800">*</span>
                  </label>
                  <input
                    class="appearance-none block w-full bg-gray-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                    id="grid-first-name"
                    type="text"
                    v-model="firstName"
                    placeholder="Enter First Name"
                  />
                </div>
                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                  <label
                    class="block  tracking-wide text-gray-700 text-xs font-bold mb-2"
                    for="grid-first-name"
                  >
                    Last Name <span class="text-red-800">*</span>
                  </label>
                  <input
                    class="appearance-none block w-full bg-gray-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                    id="grid-first-name"
                    type="text"
                    v-model="lastName"
                    placeholder="Enter Last Name"
                  />
                </div>
              </div>
              <div class="flex flex-wrap -mx-3 mb-3">
                <div class="w-full md:w-1/2 px-3">
                  <label
                    class="block  tracking-wide text-gray-700 text-xs font-bold mb-2"
                    for="grid-last-name"
                  >
                    Email <span class="text-red-800">*</span>
                  </label>
                  <input
                    class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="grid-last-name"
                    type="text"
                    placeholder="Enter Email"
                    v-model="email"
                  />
                </div>
                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                  <p class="text-xs font-bold mb-3">
                    Phone number
                    <span class="text-red-800">*</span>
                  </p>
                  <p class="text-sm font-semibold">
                    <input
                      type="number"
                      class="tel appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      name=""
                      placeholder="Enter Phone number"
                      id=""
                      v-model="phoneNumber"
                      required
                    />
                  </p>
                </div>
              </div>
              <div class="flex flex-wrap -mx-3 mb-3">
                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                  <p class="text-xs font-bold mb-3">
                    Issue type
                    <span class="text-red-800">*</span>
                  </p>
                  <p class="text-sm font-semibold">
                    <select
                      type="text"
                      class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      @change="handleIssueSelect"
                      id=""
                      v-model="issueType"
                      required
                    >
                      <option value="">Select Issue type</option>
                      <option
                        :value="issue"
                        v-for="(issue, i) in issues"
                        :key="i"
                      >
                        {{ issue.type }}
                      </option>
                    </select>
                  </p>
                </div>
                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                  <p class="text-xs font-bold mb-3">
                    Issue Sub-type
                    <span class="text-red-800">*</span>
                  </p>
                  <p class="text-sm font-semibold">
                    <select
                      type="text"
                      class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      name=""
                      id=""
                      v-model="subIssue"
                      required
                    >
                      <option value="">Select Issue Sub-type</option>
                      <option
                        :value="subtype"
                        v-for="(subtype, i) in subTypes"
                        :key="i"
                      >
                        {{ subtype.type }}
                      </option>
                    </select>
                  </p>
                </div>
              </div>
              <div class="flex flex-wrap -mx-3 mb-3">
                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                  <p class="text-xs font-bold mb-3">
                    Business Name
                    <span class="text-red-800">*</span>
                  </p>
                  <p class="text-sm font-semibold">
                    <input
                      type="text"
                      class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      placeholder="Enter Business Name"
                      id=""
                      v-model="businessName"
                      required
                    />
                  </p>
                </div>
                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                  <p class="text-xs font-bold mb-3">
                    Subject <span class="text-red-800">*</span>
                  </p>
                  <p class="text-sm font-semibold">
                    <input
                      type="text"
                      class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      placeholder="Enter Subject"
                      v-model="subject"
                      required
                    />
                  </p>
                </div>
              </div>

              <div class="flex flex-wrap -mx-3 mb-3">
                <div class="w-full md:w-full px-3">
                  <p class="text-xs font-bold mb-3">
                    Description
                    <span class="text-red-800">*</span>
                  </p>
                  <p class="text-sm font-semibold">
                    <textarea
                      class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      placeholder="Enter Description"
                      id=""
                      v-model="description"
                      required
                    ></textarea>
                  </p>
                </div>
              </div>
              <!-- <div
                class="flex flex-wrap -mx-3 mb-6"
                v-if="uploadURL.length < 1"
              >
                <div class="w-full md:w-full px-3">
                  <p class="text-xs font-bold mb-3">
                    Upload Attachment
                  </p>
                  <DraggableComponent
                    @handleFile="onHandleFile"
                    acceptParams=".png,.jpg,.jpeg"
                    :allowMultiple="true"
                    :dnd="false"
                  />
                  <span class="loader-line" v-if="isLoadingInline"></span>
                </div>
              </div>
              <div class="flex flex-wrap -mx-3 mb-6" v-else>
                <div class="w-full md:w-full px-3">
                  <p class="text-xs font-bold mb-3">
                    Upload Attachment (Max uploads: 5)
                  </p>
                  <div class="flex justify-center">
                    <div
                      class="text-xs relative"
                      v-for="(file, i) in uploadURL"
                    >
                      <i
                        @click="removeImage(i)"
                        class="bi bi-x-circle-fill cursor-pointer text-red-600 text-lg absolute top-0 right-0"
                      ></i>
                      <img
                        :src="file"
                        style="width:100px;height:100px;"
                        class="mx-2"
                      />
                    </div>
                  </div>
                </div>
              </div> -->
              <div class="w-full flex mt-5">
                <div class="my-3 w-80">
                  <button
                    class="py-3 mr-5 px-10 bg-yellow-300 rounded-md font-semibold text-black text-sm"
                    @click="resetFields"
                    type="button"
                  >
                    Reset
                  </button>
                  <button
                    class="py-3 px-10 bg-blue-800 rounded-md font-semibold text-white text-sm"
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <!-- <div class="w-full xl:mb-4 xl:px-4">
          <p
            :class="errorMssgColor ? 'text-red-800' : 'text-green-600'"
            v-if="errorMssg"
          >
            {{ errorMssg }}
          </p>
        </div> -->
        <div
          v-if="promptModal"
          class="modal fixed bg-opacity-50 bg-gray-900 flex justify-center items-center w-screen h-screen top-0 left-0 z-40"
        >
          <div
            class="w-full md:w-1/2 rounded-lg bg-white p-5 grid grid-cols-1 gap-4 text-center"
          >
            <div class="block">
              <h4 class="text-center font-semibold">
                Message was sent sucessfully
              </h4>
              <p
                class="my-4 font-bold"
                :class="errorMssgColor ? 'text-red-800' : 'text-blue-800'"
                v-if="errorMssg"
              >
                {{ errorMssg }}
              </p>
              <button
                @click="promptModal = false"
                class="
                    block
                    uppercase
                    mx-auto
                    hover:bg-transparent
                    hover:text-red-600
                    bg-red-600
                    focus:shadow-outline
                    focus:outline-none
                    text-white text-xs
                    font-bold
                    py-2
                    px-4
                    rounded
                  "
                style="width:inherit"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- </div> -->
    </div>
  </main>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import DraggableComponent from "../components/DraggableComponent.vue";
export default {
  name: "TractionSupportForm",
  data() {
    return {
      moment,
      isLoading: false,
      uploadURL: [],
      isLoadingInline: false,
      files: [],
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      businessName: "",
      issueType: "",
      subIssue: "",
      subject: "",
      description: "",
      issues: [],
      subTypes: [],
      subType: "",
      promptModal: false,
      errorMssg: "",
      errorMssgColor: false,
    };
  },
  components: {
    DraggableComponent,
  },
  async mounted() {},
  filters: {
    truncate: function(text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
  },
  async created() {
    await this.fetchIssues();
  },
  methods: {
    async fetchIssues() {
      this.isLoading = true;
      try {
        let res = await this.$store.dispatch("FETCH_ALL_ISSUES");
        if (res.status) {
          this.issues = res.data.supportIssues;
        }
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
    async onHandleFile(files) {
      if (files.length > 0 && files.length <= 5) {
        this.isLoadingInline = true;
        this.files = files;
        let counter = 1;
        files.forEach(async (file) => {
          try {
            let res = await this.$store.dispatch(
              "HANDLE_FILE_NO_AUTH_UPLOAD",
              file
            );
            if (res.status == true) {
              this.uploadURL.push(res.data);
              counter++;
            } else {
              alert(
                res.error ||
                  res.message ||
                  "Error: Please check network or contact admin."
              );
            }
          } catch (error) {}
        });
        if (files.length === counter) this.isLoadingInline = false;
      }
    },
    removeImage(i) {
      this.uploadURL.splice(i, 1);
      this.isLoadingInline = false;
    },
    handleIssueSelect() {
      // console.log(this.issueType.subIssues);
      this.subTypes = this.issueType.subIssues;
    },
    async newSupportFormRequest() {
      this.isLoading = true;
      console.log(this.subIssue);
      let payload = {
        firstName: this.firstName,
        lastName: this.lastName,
        businessName: this.businessName,
        phoneNumber: this.phoneNumber,
        email: this.email,
        issueType: this.issueType.type,
        subIssue: this.subIssue.type,
        subject: this.subject,
        description: this.description,
        attachments: this.uploadURL,
      };
      try {
        let res = await this.$store.dispatch(
          "CREATE_NEW_SUPPORT_REQUEST",
          payload
        );
        if (res.status === true) {
          this.errorMssg = res.message;
          this.promptModal = true;
          this.firstName = "";
          this.lastName = "";
          this.businessName = "";
          this.phoneNumber = "";
          this.email = "";
          this.issueType.type = "";
          this.subIssue.type = "";
          this.subject = "";
          this.description = "";
          this.uploadURL = [];
          this.isLoadingInline = false;
        } else {
          this.errorMssg = res.message;
          this.errorMssgColor = true;
          this.isLoading = false;
        }
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
      this.isLoadingInline = false;
    },
    resetFields() {
      this.firstName = "";
      this.lastName = "";
      this.businessName = "";
      this.phoneNumber = "";
      this.email = "";
      this.issueType.type = "";
      this.subIssue.type = "";
      this.subject = "";
      this.description = "";
      this.uploadURL = [];
    },
  },
};
</script>

<style scoped>
.tel::-webkit-inner-spin-button,
.tel::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.tel {
  -moz-appearance: textfield;
}
table {
  border-collapse: separate;
  border-spacing: 0;
  min-width: 350px;
}
table tr th:last-child {
  border-right: 1px solid rgb(241, 241, 241);
}
table tr th,
table tr td {
  /* border-right: 1px solid #bbb; */
  border-bottom: 1px solid rgb(238, 238, 238);
  padding: 18px;
}
table tr th:first-child,
table tr td:first-child {
  /* border-right: 1px solid rgb(223, 223, 223); */
  border-left: 1px solid rgb(241, 241, 241);
}
table tr th {
  background: rgb(255, 255, 255);
  border-top: 1px solid rgb(241, 241, 241);
  text-align: left;
}
table tr td {
  background: #f0f6fd;
}
/* top-left border-radius */
table tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

/* top-right border-radius */
table tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

/* bottom-left border-radius */
table tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
table tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}
.inline-loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.inline-loading:not(:required):after {
  content: "";
  display: block;
  font-size: 10px;
  width: 0.5em;
  height: 0.5em;
  margin-top: -0.5em;
  -webkit-animation: spinner 1500ms infinite linear;
  -moz-animation: spinner 1500ms infinite linear;
  -ms-animation: spinner 1500ms infinite linear;
  -o-animation: spinner 1500ms infinite linear;
  animation: spinner 1500ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0,
    rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0,
    rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.5) -1.5em 0 0 0,
    rgba(0, 0, 0, 0.5) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0,
    rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
  box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0,
    rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0,
    rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) -1.5em 0 0 0,
    rgba(0, 0, 0, 0.75) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0,
    rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.icon-cancel {
  position: absolute;
  top: 8%;
  right: 1%;
}

.icon-sm-cancel {
  position: absolute;
  top: 15%;
  right: 76.5%;
}

.icon-sm-clear-cancel {
  position: absolute;
  top: 15%;
  right: 42%;
}

.loader-line {
  width: 100%;
  height: 3px;
  position: relative;
  overflow: hidden;
  background-color: #ddd;
  /* margin: 100px auto; */
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  display: block;
}

.loader-line:before {
  content: "";
  position: absolute;
  left: -50%;
  height: 3px;
  width: 40%;
  background-color: #003282;
  -webkit-animation: lineAnim 1s linear infinite;
  -moz-animation: lineAnim 1s linear infinite;
  animation: lineAnim 1s linear infinite;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

@keyframes lineAnim {
  0% {
    left: -40%;
  }
  50% {
    left: 20%;
    width: 80%;
  }
  100% {
    left: 100%;
    width: 100%;
  }
}
</style>
